/*
	Map
	------

	Google Maps Hacks and tricky tricks

 */
;(function mapHacks($) {

	// $('#map__canvas').click(function(){
	// 	$('.gm-style-iw').hide();
	// });

})(jQuery);